import { gql } from '@/__generated__';

export const TournamentPostCardFragment = gql(`
  fragment TournamentPostCardFragment on TournamentPost {
    ...BaseTournamentPostCardFragment
    voted
    repostId
    author {
      ...PostCardAuthorFragment
    }
  }
`);
